import React from 'react'
import tw, { styled, css } from 'twin.macro'

import { SocialLinks } from './SocialLinks'
import PostLinks from './PostLinks'
import { ContentContainer, H2, PageSection } from '../layout/Layout'
import { ROUTES } from '../layout/Navigation'
import { AnalyticsConversionLink } from '../analytics/AnalyticsConversionLink'

const ImageContainer = styled.div<{}>(() => [
  tw`w-full`,
  css`
    height: 220px;

    @media (min-width: 768px) {
      height: 400px;
    }
  `
])

const PostDate = tw.div`text-center text-sm text-indigo-300`

const HeadingHero = tw.h2`text-2xl sm:text-4xl font-bold text-white text-center`

const Image = tw.img`object-cover w-full h-full`

export const BlogHero = ({ post, postIndex }) => {
  return (
    <>
      {post.heroImage && (
        <ImageContainer tw="mb-10 sm:mb-14">
          <Image src={post.heroImage.sizes.src} />
        </ImageContainer>
      )}
      <ContentContainer>
        <PostDate>{post.publishDate}</PostDate>        
        <HeadingHero>{post.title}</HeadingHero>
      </ContentContainer>
    </>
  )
}

const LinksContainer = styled.div`
  max-width: 65ch;
  ${tw`m-auto`}
`

export const BlogContent = ({ post, postIndex }) => {
  return (
    <>
      <PageSection>
        <ContentContainer>
          <article tw="prose m-auto" dangerouslySetInnerHTML={{ __html: post.body.childMarkdownRemark.html }}/>
          <LinksContainer tw="mt-20">
            <SocialLinks title={post.title} />
            <PostLinks tw="mt-8" previous={postIndex.previous} next={postIndex.next} />
          </LinksContainer>
        </ContentContainer>
      </PageSection>
      <BlogDetailsCTA />
    </>
  )
}


const BlogDetailsCTA = () => (
  <PageSection tw="sm:py-24 bg-gray-100">
    <ContentContainer tw="lg:flex lg:items-center lg:justify-between">
      <div tw="max-w-xl">
        <H2>
          It's Time. Get Your Money Back Now.
        </H2>
        <p tw="mt-4 text-sm sm:text-xl leading-6 sm:leading-7">
        Remove the uncertainty and hassle by letting our solicitors do the heavy lifting for you.
        </p>
      </div>
      <div tw="mt-8 flex flex-col items-center lg:flex-shrink-0 lg:mt-0">
        <AnalyticsConversionLink to={ROUTES.start.case}>
          Get started now
        </AnalyticsConversionLink>

        <p tw="mt-2 text-xs sm:text-sm font-medium leading-7 text-gray-700">
          Digital Application. Takes under 5 minutes
        </p>
      </div>
    </ContentContainer>
  </PageSection>
)
