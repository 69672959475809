import React, { FC } from 'react'
import tw from 'twin.macro'
import { Link } from 'gatsby'
import { NextPrevious }  from './NextPrevious'

const Wrapper = tw.div`flex flex-row justify-between`

const DirectionLink = tw(Link)`text-gray-400 font-medium border-b-2 border-transparent hover:(text-indigo-600 border-indigo-600)`

const PostLinks: FC = (props: any) => {
  return (
    <Wrapper {...props}>
      {props.previous && (
        <DirectionLink to={`/knowledge-hub/${props.previous.slug}/`}><NextPrevious direction='left'/> Prev Post</DirectionLink>
      )}
      {props.next && <DirectionLink to={`/knowledge-hub/${props.next.slug}/`}>Next Post <NextPrevious direction='right'/></DirectionLink>}
    </Wrapper>
  )
}

export default PostLinks