import React, { FC } from 'react'
import { styled } from 'twin.macro'

let Wrapper = styled.span<{position?: any}>`
  position: ${props => props.position};
  margin-top: 1.8px;
`

type NextPreviousProps = { direction: string, position?: string }

export const NextPrevious: FC<NextPreviousProps> = ({direction, position}) => {
  if (direction === 'right') {
    return (
      <Wrapper position={position}>&rarr;</Wrapper>
    )
  }
  if (direction === 'left') {
    return (
      <Wrapper position={position}>&larr;</Wrapper>
    )
  }
}
