import React, { useEffect, FC, useState } from 'react'
import tw from 'twin.macro'

const Facebook = () => (
  <svg width="11px" height="24px" viewBox="0 0 11 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Home-Page" transform="translate(-227.000000, -698.000000)" fill="currentColor">
        <path d="M234.314261,722 L229.43769,722 L229.43769,710.000632 L227,710.000632 L227,705.863921 L229.43769,705.863921 L229.43769,703.381642 C229.43769,700.008321 230.814478,698 234.721933,698 L237.97616,698 L237.97616,702.136711 L235.943758,702.136711 C234.420351,702.136711 234.320221,702.715572 234.320221,703.794934 L234.314261,705.863921 L238,705.863921 L237.568487,710.000632 L234.314261,710.000632 L234.314261,722 L234.314261,722 Z" id="Shape" />
      </g>
    </g>
  </svg>
)

const LinkedIn = () => (
  <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Home-Page"
        transform="translate(-340.000000, -698.000000)"
        fill="currentColor"
      >
        <path d="M364.078991,722 L341.922311,722 C340.860289,722 340,721.228985 340,720.2782 L340,699.720468 C340,698.771015 340.860289,698 341.922311,698 L364.078991,698 C365.139711,698 366,698.771015 366,699.720468 L366,720.2782 C366,721.228985 365.139711,722 364.078991,722 L364.078991,722 Z M344.505782,718.046385 L348.334785,718.046385 L348.334785,707.210897 L344.505782,707.210897 L344.505782,718.046385 L344.505782,718.046385 Z M346.446313,701.988237 C345.135706,701.988237 344.280623,702.796538 344.280623,703.860512 C344.280623,704.899184 345.110978,705.732786 346.395555,705.732786 L346.420283,705.732786 C347.755619,705.732786 348.585974,704.899184 348.585974,703.860512 C348.562547,702.796538 347.755619,701.988237 346.446313,701.988237 L346.446313,701.988237 Z M362.141062,711.834323 C362.141062,708.505243 360.251289,706.956556 357.732893,706.956556 C355.699955,706.956556 354.79151,708.007213 354.282625,708.746269 L354.282625,707.210897 L350.453622,707.210897 C350.50438,708.228264 350.453622,718.046385 350.453622,718.046385 L354.282625,718.046385 L354.282625,711.99545 C354.282625,711.673195 354.307353,711.348277 354.40887,711.117905 C354.684788,710.470732 355.316013,709.800921 356.372829,709.800921 C357.758923,709.800921 358.312059,710.794318 358.312059,712.251124 L358.312059,718.046385 L362.141062,718.046385 L362.141062,711.834323 L362.141062,711.834323 Z M354.256595,708.782223 C354.264404,708.770238 354.274816,708.758253 354.282625,708.746269 L354.282625,708.782223 L354.256595,708.782223 L354.256595,708.782223 Z" id="Shape" />
      </g>
    </g>
  </svg>
)

const Twitter = () => (
  <svg width="31px" height="24px" viewBox="0 0 31 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Home-Page" transform="translate(-161.000000, -698.000000)" fill="currentColor">
        <path d="M192,700.842322 C190.859339,701.325292 189.633092,701.647772 188.34782,701.794763 C189.661129,701.04481 190.668983,699.856884 191.144136,698.443972 C189.913462,699.136929 188.552932,699.640897 187.103865,699.91388 C185.945497,698.734954 184.291318,698 182.463014,698 C178.951019,698 176.10158,700.71333 176.10158,704.058121 C176.10158,704.533592 176.157654,704.997063 176.266851,705.439535 C170.98115,705.186051 166.294554,702.774202 163.157369,699.109931 C162.609911,700.005375 162.297077,701.04481 162.297077,702.15624 C162.297077,704.256109 163.42003,706.112993 165.125857,707.198925 C164.084063,707.165927 163.104246,706.894444 162.24543,706.439973 L162.24543,706.517968 C162.24543,709.453284 164.436738,711.901131 167.346677,712.457596 C166.813976,712.595588 166.250286,712.667583 165.671839,712.667583 C165.261615,712.667583 164.863195,712.631586 164.475105,712.56259 C165.283749,714.96694 167.632949,716.72033 170.415984,716.769827 C168.239433,718.394225 165.497715,719.361665 162.516946,719.361665 C162.003427,719.361665 161.497287,719.333167 161,719.27767 C163.814023,720.995063 167.157797,722 170.749476,722 C182.448258,722 188.845107,712.766577 188.845107,704.761577 C188.845107,704.497594 188.837728,704.23811 188.825923,703.977126 C190.068403,703.12218 191.147087,702.055747 192,700.842322 L192,700.842322 Z" id="Shape" />
      </g>
    </g>
  </svg>
)

const SocialContainer = tw.div`flex flex-row`

const StyledSocialLink = tw.a`hover:text-indigo-600 text-gray-800 mr-4 last:mr-0`

type SocialLinkProps = {
  url: string,
  icon: any
}
const SocialLink: FC<SocialLinkProps> = ({ icon, url, ...rest }) => (
  <StyledSocialLink target="_blank" href={url} {...rest}>
    {icon}
  </StyledSocialLink>
)

type SocialLinksProps = {
  title: string
}

export const SocialLinks: FC<SocialLinksProps> = ({ title }) => {
  const [urlEncodedLocation, setUrlEncodedLocation] = useState('')

  useEffect(() => {
    // Update the document title using the browser API
    setUrlEncodedLocation(encodeURIComponent(window.location.toString()))
  }, [])

  return (
    <SocialContainer>
      <span tw="mr-4 text-gray-600 font-medium text-sm">
        Share on: 
      </span>

      <SocialLink
        url={`https://twitter.com/intent/tweet?text=${urlEncodedLocation}`}
        icon={<Twitter />}
      />
      <SocialLink
        url={`https://www.facebook.com/sharer/sharer.php?u=${urlEncodedLocation}`}
        icon={<Facebook />}
      />
      <SocialLink
        url={`https://www.linkedin.com/shareArticle?mini=true&url=${urlEncodedLocation}&title=${title}`}
        icon={<LinkedIn />}
      />
    </SocialContainer>
  )
}
