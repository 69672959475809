import React, { FC } from 'react'
import find from 'lodash/find'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import config from '../config/site'
import { SEO } from '../components/seo/SEO'
import { BlogHero, BlogContent } from '../components/blog/BlogDetails'
import { HeroTemplate } from '../components/layout/HeroTemplate'

const PostTemplate: FC<{data: any}> = ({ data }) => {
  const {
    title,
    slug,
    id
  } = data.contentfulPost
  const postNode = data.contentfulPost

  const postIndex = find(
    data.allContentfulPost.edges,
    ({ node: post }) => post.id === id
  )

  return (
    <>
      <HeroTemplate 
        hero={<BlogHero post={data.contentfulPost} postIndex={postIndex} />}
        bottom={<BlogContent post={data.contentfulPost} postIndex={postIndex} />}
      >
        <Helmet>
          <title>{`LawHive | ${title} - ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postPath={`blog/${slug}`} postNode={postNode} postSEO />
      </HeroTemplate>
    </>
  )
}

export const query = graphql`
  query postQuery($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      id
      slug
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        sizes(maxWidth: 1800) {
          ...GatsbyContentfulSizes_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
    allContentfulPost(
      limit: 1000
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          id
        }
        previous {
          slug
        }
        next {
          slug
        }
      }
    }
  }
`

export default PostTemplate