import { Transition } from '@tailwindui/react'
import React, { FC, useState } from 'react'
import tw, { css } from 'twin.macro'

import { Layout } from './Layout'
import { DesktopNavigation, MobileNavigation } from './Navigation'


type TemplateProps = {
  hero: JSX.Element
  bottom: JSX.Element
}

export const HeroTemplate: FC<TemplateProps> = ({ hero, bottom, children }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const toggleMobileNav = () => setMobileNavOpen(!mobileNavOpen)

  return (
    <Layout>
      <div>
        <div tw="bg-indigo-900 py-4">
          <DesktopNavigation onToggleMobile={toggleMobileNav} />
        </div>

        <Transition
          show={mobileNavOpen}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div ref={ref} tw="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <MobileNavigation onToggleMobile={toggleMobileNav} />
            </div>
          )}
        </Transition>

        <div tw="bg-indigo-900 md:overflow-hidden">
          <div tw="pb-20 py-10 sm:py-20 md:py-4">
            {hero}
          </div>
          <div tw="relative" css={[
            tw`h-0`,
            css`@media (min-width: 768px) { height: calc(100vw * (160/1440) - 4px) }`
          ]}>
            <svg
              tw="fill-current text-white hidden md:block z-50 absolute"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 160"
            >
              <path fill-opacity="1" d="M0,124L1440,32L1440,320L0,320Z"></path>
            </svg>
          </div>
        </div>

        {bottom}
      </div>

      {children}
    </Layout>
  )
}